import { render, staticRenderFns } from "./Material.vue?vue&type=template&id=4108de4e&scoped=true"
import script from "./Material.vue?vue&type=script&lang=js"
export * from "./Material.vue?vue&type=script&lang=js"
import style0 from "./Material.vue?vue&type=style&index=0&id=4108de4e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4108de4e",
  null
  
)

component.options.__file = "Material.vue"
export default component.exports